import React from "react"

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { error: null }
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error })
    if (typeof window !== "undefined") {
      if (window.Sentry) {
        window.Sentry.configureScope((scope) => {
          Object.keys(errorInfo).forEach((key) => {
            scope.setExtra(key, errorInfo[key])
          })
        })
        window.Sentry.captureException(error)
      }
    }
  }

  render() {
    if (this.state.error) {
      // render fallback UI
      return (
        <h1>
          Something went wrong - please try to refresh. If the issue persist
          please reach out.
        </h1>
      )
    } else {
      // when there's not an error, render children untouched
      return this.props.children
    }
  }
}

export default ErrorBoundary
