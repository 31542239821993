import React from "react"
import { Box } from "theme-ui"

import loadable from "@loadable/component"

import { CheckoutLayout } from "../layouts-v2/checkout"
import Confirmation from "../v2/checkout/Confirmation"

const OrderSummary = loadable(() => import("../v2/checkout/OrderSummary"), {
  ssr: false,
})

const CheckoutDone = ({ order, isSplitOrder = false }) => {
  return (
    <CheckoutLayout>
      <Box sx={{ display: [null, "flex"], minHeight: [null, "100vh"] }}>
        <OrderSummary order={order} canEdit={false} step="complete" />
        <Box
          sx={{
            width: [null, "55%"],
            paddingBlockStart: [6, 23],
            paddingBlockEnd: [null, 23],
          }}
        >
          <Box
            sx={{
              position: [null, "sticky"],
              top: [null, 10],
              maxWidth: 147,
              marginInline: "auto",
              paddingInline: 4,
            }}
          >
            <Box>
              <Confirmation order={order} isSplitOrder={isSplitOrder} />
            </Box>
          </Box>
        </Box>
      </Box>
    </CheckoutLayout>
  )
}

export default CheckoutDone
