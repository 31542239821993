import React, { useState, useEffect } from "react"
import styled from "@emotion/styled"

const ProcessingWaiter = styled.div`
  position: ${(props) => (props.isButtonSpinner ? "inline-block" : "absolute")};
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background-color: ${(props) =>
    props.bg ? props.bg : "rgba(255, 255, 255, 0.9)"};
  height: ${(props) =>
    props.height ? props.height : props.isButtonSpinner ? "100%" : "110vh"};

  display: flex;
  justify-content: space-around;
  align-items: center;

  i {
    font-size: ${(props) => (props.isButtonSpinner ? "30px" : "40px")};
  }
`

const LoadingSpinner = ({
  bg = null,
  isButtonSpinner = false,
  height = null,
}) => {
  const [showSpinner, setShowSpinner] = useState(false)

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowSpinner(true)
    }, 200)

    return () => clearTimeout(timer)
  }, [])

  return (
    <ProcessingWaiter bg={bg} isButtonSpinner={isButtonSpinner} height={height}>
      {showSpinner && <i className="fal fa-spinner-third" />}
    </ProcessingWaiter>
  )
}

export default LoadingSpinner
